body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    height: 100vh;
    display: flex;
    background: #f0f2f5;
  }
  
  .container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    box-sizing: border-box;
  }
  
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
  }
  
  .text {
    color: #3c009d;
    font-size: 32px;
    font-weight: 700;
  }
  
  .underline {
    width: 61px;
    height: 6px;
    background: #3c009d;
    border-radius: 9px;
  }
  
  .inputs {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background: #eaeaea;
    border-radius: 6px;
  }
  
  .input input {
    height: 100%;
    width: 100%;
    background: transparent;
    border: none;
    padding: 0 15px;
    font-size: 16px;
    outline: none;
  }
  
  .submit-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    color: #fff;
    background: #4c00b4;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  
  .error {
    color: red;
    gap: 20px;
  }