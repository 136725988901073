body {
    margin: 0;
    padding: 0;
}
.header-text {
    font-size: 2.5rem;
    color: black;
    font-weight: bold;
    height: 60px;  /* Add px */
    margin-top: 30px;  /* Add px */
}

.container1 {
    min-height: 100vh;
    min-width: 98vw;
    display: flex;
    flex-direction: row;
}
